import ArrowLeft from '@/icons/arrow-left.svg'
import ArrowRight from '@/icons/arrow-right.svg'
import { chakra, Icon, IconButton } from '@chakra-ui/react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const ChakraCarousel = chakra(ReactCarousel)

const ArrowButton = ({ icon, label, ...props }) => (
  <IconButton
    className="carousel-arrow"
    icon={icon}
    rounded="full"
    bg="rgba(255,255,255,0.5) !important"
    pos="absolute !important"
    top="50% !important"
    transform="translate(0%, -50%)"
    title={label}
    zIndex={2}
    cursor="pointer"
    size="sm"
    border="none !important"
    _focus={{
      boxShadow: 'none !important'
    }}
    _hover={{
      backgroundColor: 'white !important'
    }}
    {...props}
  />
)

const NextArrow = (onClickHandler, hasNext, label) => {
  if (!hasNext) return null

  return (
    <ArrowButton
      icon={<Icon as={ArrowRight} fill="gray.800" boxSize={3} />}
      right="6"
      label={label}
      onClick={onClickHandler}
      boxSize={12}
    />
  )
}

const PrevArrow = (onClickHandler, hasPrev, label) => {
  if (!hasPrev) return null

  return (
    <ArrowButton
      icon={<Icon as={ArrowLeft} fill="gray.800" boxSize={3} />}
      left="6"
      label={label}
      onClick={onClickHandler}
      boxSize={12}
    />
  )
}

export const Carousel = ({
  showArrowsOnHover = false,
  showArrows = true,
  arrowSize = 12,
  ...props
}) => {
  const arrowsVisible = showArrowsOnHover || showArrows
  return (
    <ChakraCarousel
      infiniteLoop
      showStatus={false}
      showArrows={arrowsVisible}
      showIndicators={false}
      showThumbs={false}
      renderArrowNext={NextArrow}
      renderArrowPrev={PrevArrow}
      sx={{
        ...(showArrowsOnHover && {
          'button.carousel-arrow': {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0.1s ease-in,opacity 0.1s ease-in'
          },
          _hover: {
            'button.carousel-arrow': {
              visibility: 'visible',
              opacity: 1
            }
          }
        }),
        'div.slider-wrapper': {
          borderTopRadius: '6px'
        },
        '.chakra-button': {
          w: arrowSize,
          h: arrowSize
        }
      }}
      {...props}
    />
  )
}
